import React from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageCompat from "../components/image"
import { spacing } from "../style/theme"

const PersonHeader = styled.div`
  display: flex;
  margin-bottom: ${spacing.large};
`
const ImageWrapper = styled.div`
  width: 200px;
  margin-left: auto;
`

interface PersonProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        name: string
        body: string
        websiteUrl: string
        image: {
          childImageSharp: {
            fluid: FluidObject | FluidObject[]
          }
        } | null
      }
    }
  }
}
const Person: React.FC<PersonProps> = props => {
  const {
    data: {
      markdownRemark: {
        html,
        frontmatter: { name, websiteUrl, image },
      },
    },
  } = props

  return (
    <Layout>
      <SEO title={name} />
      <PersonHeader>
        <div>
          <h1>{name}</h1>
          {websiteUrl && <a href={websiteUrl}>{websiteUrl}</a>}
        </div>
        {image && (
          <ImageWrapper>
            <ImageCompat image={image} alt="profile picture" />
          </ImageWrapper>
        )}
      </PersonHeader>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default Person

export const personQuery = graphql`
  query Person($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        name
        websiteUrl
        image {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
